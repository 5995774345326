.row {
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  @include rfs(gap, 120);
  @media (orientation: portrait) {
    justify-content: center;
  }
  &:not([class^='width--']) {
    width: 100%;
  }
  &--gap {
    &-50 {
      @include rfs(gap, 50);
    }
  }
  .col {
    padding-left: 0;
    padding-right: 0;
    display: flex;
    flex-direction: column;
    flex: 1;
    @media (orientation: portrait) {
      flex: 0 0 100%;
    }
  }
}
