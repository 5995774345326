#main-menu,
#main-menu ul {
  height: 100%;
}

#main-menu ul {
  position: relative;
  list-style-type: none;
  text-align: left;
}

#menu-overlay {
  z-index: 12;
  pointer-events: none;
}

#labels-overlay {
  z-index: 1;
}

.main-menu {
  &__sensibles {
    pointer-events: all;
    &--top,
    &--right,
    &--bottom,
    &--left,
    &--language {
      position: fixed;
      z-index: 10;
    }
    &--left,
    &--right {
      width: rvh(50);
      height: 100vh;
      bottom: 0;
    }
    &--top,
    &--bottom {
      width: 100vw;
      height: rvh(50);
      left: 0;
    }
    &--top {
      top: 0;
    }
    &--bottom {
      bottom: 0;
    }
    &--left {
      left: 0;
    }
    &--right {
      right: 0;
      @media screen and (orientation: landscape) {
        right: 30px; // avoid scrollbar overlap
      }
    }

    &--language {
      right: rvh(80);
      top: rvh(30);
      z-index: 20;
      display: flex;
      align-items: center;
      gap: 0 rvh(10);
      @media screen and (orientation: portrait) {
        bottom: 5vw;
        left: 5vw;
        top: unset;
        right: unset;
      }
      button {
        background-color: transparent;
        color: $color-primary;
        border: none;
        font-weight: 700;
        cursor: pointer;
        @extend .t-20;
        &.active {
          color: yellow;
          text-shadow: 2px 2px 2px #00000040;
        }
      }
    }
  }
}
