.images-two-columns {
  .row > div {
    display: flex;
    // align-items: center;
    // justify-content: flex-end;
    @media (orientation: portrait) {
      width: 100%;
    }
  }
}
